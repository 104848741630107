import lieu from "../Component/lieux.png";
import l8 from "../Component/8.png";
import l1 from "../Component/1.png";
export default function Home(){
    return (
        <div className="contact">
            <div className="block green">
                <div className="pt-10">       
                    <div className="grid g5050">
                        <div>
                            <img className="little" src={lieu}></img>
                        </div>
                        <div className="adresse">
                            <h1>Où nous trouver ?</h1>
                            <p className="bold">201 rue du Faubourg Saint Antoine</p>
                            <p>75011, Paris 11 ème Arrondissement</p>
                            <p className="space"></p>
                            <p className="bold">A proximité de :</p>
                            <div>
                                <img className="little" src={l8}></img>
                                <p>Métro 8 : Faubourg Chaligny (100m)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block center">
                    <h2>Nous contacter</h2>
                    <div className="formContact">
                        <form>
                            <div>
                                <input type="text" placeholder="Email"></input>
                            </div>
                            <div>
                                <textarea placeholder="Votre message"></textarea>
                            </div>
                            <div>
                                <input type="submit" value="Envoyer"></input>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
    )
}