import { NavLink } from "react-router-dom";
import logo from "../Component/logo.png";
export default function Menu(){
    return(
        <div className="menu">
                <div className="big"><img src={logo}></img></div>
                <div className="other"><NavLink to="/">Accueil</NavLink></div>
                <div className="other"><NavLink to="/">Engagement</NavLink></div>
                <div className="other"><NavLink to="/contact">Contact</NavLink></div>
        </div>
    )
}