import { Routes, Route} from 'react-router-dom';
import './css/app.css';
import Home from './Component/Home';
import Menu from './Component/Menu';
import Contact from './Component/Contact';

function App() {

  return (
    <>
      <Menu/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      
    </>
  );
}

export default App;
