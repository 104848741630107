import SA from "../Component/sansabri.png";
import rect from "../Component/rectangle.png"
import aide from "../Component/aide.jpg"
import engagement from "../Component/aides.jpg"
export default function Home(){
    return (
        <div className="home">
            <div className="block">
                <img id="bck" src={SA}></img>
                <div className="textImg">
                    <p>Chaque année 321 sans-abris meurent de faim,</p>
                    <p>Car ils ne peuvent s’offrir de nourriture.</p>
                    <p class="space"></p>
                    <p>Aidons les à retravailler pour qu’ils puissent </p>
                    <p>revivre comme avant</p>
                </div>
            </div>
            <div className="block blue">
                <div class="grid g5050">
                    <div>
                        <img class="short" src={aide}></img>
                    </div>
                    <div>
                        <h2>Nos missions</h2>
                        <div className="textContainer">
                            <p className="texte">Notre mission est de permettre au sans-abris
                            de pouvoir bénéficier d'une adresse postale et d'une aide pour la recherche d'emploi.
                            Notre accompagnement va permettre de remettre d'aplomb des personnes ayant perdu les notions professionneles et sociales actuelles.
                            Ainsi nous les formons à pouvoir se réintégrer dans la société et leur permettre aussi de se développer personnellement et professionnellement.
                            Grâce à une équipe de bénévole, nous allons permettre d'aider aussi des entreprises à avoir du personnel et au sans-abris d'avoir un emploi.
                            Nous espérons aider ainsi près de 1000 sans-abris à trouver un emploi avant 2025.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block pd5">
                <div className="grid g5050 black">
                    <div className="black">
                        <h2 id="black">Donation</h2>
                        <div className="textContainer2">
                            <p>Nous sommes un petite association situé en région parisienne, nous avons donc besoin de financement pour subsiter.
                                Nous contons beaucoup sur vos donations afin de nous aider au mieux dans notre missions.

                                Voici le lien de notre plateforme de donation sécurisé pour faire les donations : 
                            </p>
                            <button className="bluebtn">Mon engagement</button>
                        </div>
                    </div>
                    <div class="">
                        <div className="grid g5050">
                            <h1 className="Bigreen">50%</h1>
                            <div>
                                <h3 className="bold">Objectifs :</h3>
                                <p>150 000€ de la cagnotte de financement</p>
                            </div>
                        </div>
                        <div className="progressbar">
                            <div className="progess"></div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="block blue white">
                <div className="grid g5050">
                    <div>
                        <img className="short mt-3" src={engagement}></img>
                    </div>
                    <div>
                        <h2 className="white">Engagement</h2>
                        <div className="textContainer">
                            <p className="white">Si vous souhaitiez vous engager auprès de notre association, voici un formulaire de contact permettant
                            de nous rejoindre. Bien sur, vous allez passer quelques entretien afin de voir si vous êtes disponible et conformes à nos 
                            critère de le développement de l'association :
                            </p>
                            <button className="greentxt">Mon engagement</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}